import { Flex } from "@chakra-ui/react";
import { GridItem, SimpleGrid } from "@chakra-ui/react"
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import { ProductDetailBreadcrumb } from "../../components/ProductDetailBreadcrumb/ProductDetailBreadcrumb";
import { ProductDetailMainCard } from "../../components/ProductDetailMainCard/ProductDetailMainCard";
import { ProductDetailSheet } from "../../components/ProductDetailSheet/ProductDetailSheet";
import ProductDetailOrderCheckout from "../../components/ProductDetailOrderCheckout/ProductDetailOrderCheckout";
import ProductDetailOptions from "../../components/ProductDetailOptions/ProductDetailOptions";
import { ProductDetailDescription } from "../../components/ProductDetailDescription/ProductDetailDescription";

import * as productsGrid from "../productGridPage/ducks/productsGrid.duck";
import useTitle from "../../hooks/useTitle";

const ProductDetailPage = ({ getOneProduct, ...props }) => {
  const mainState = useSelector((state) => state);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [filteredProviders, setFilteredProviders] = useState("");
  const [product, setProduct] = useState("");
  const history = useHistory();

  const { sku } = useParams();
  let storageFiltersLS = JSON.parse(localStorage.getItem("storageFilters")) || {};

  useEffect(() => {
    getOneProduct(sku);
  }, [getOneProduct, sku]);

  useEffect(() => {
    let _selectedZone = selectedZone;
    let filteredProvider = "";
    let filteredProviders = [];

    if(_selectedZone === "") {
      if(mainState?.cart?.items?.items && mainState?.cart?.items?.items[0]?.region?.code) {
        _selectedZone = mainState?.cart?.items?.items[0]?.region?.code;
        setSelectedZone(_selectedZone);
      }
      else if (storageFiltersLS?.delivery_zones?.delivery_zone_id.length > 0 && mainState?.productsGrid?.product?.prices) {
        let _regions = mainState.productsGrid.product.prices;
        _regions = Object.entries(_regions)
            .map(([key, prices]) =>
            {
              let _prices = prices.filter((price) => price.region_id === storageFiltersLS.delivery_zones.delivery_zone_id[0]);
              if (_prices.length > 0)return key;
              return null;
            }).filter((region) => region !== null);

            /*.filter(([key, prices]) =>
                [key, prices.filter(
                    (price) =>
                        price.region_id === storageFiltersLS.delivery_zones.delivery_zone_id[0]
                )]
            );*/
        _selectedZone = _regions[0];
        setSelectedZone(_selectedZone);
      }
      else if (mainState?.cart && mainState?.productsGrid?.product?.prices) {
        _selectedZone = Object.keys(mainState?.productsGrid?.product?.prices)[0];
        setSelectedZone(_selectedZone);
      }
    }
    if(_selectedZone !== "" && mainState?.productsGrid?.product?.prices.length !== 0) {

      filteredProvider = mainState?.productsGrid?.product?.prices[_selectedZone][0];
      filteredProviders = mainState?.productsGrid?.product?.prices[_selectedZone];
      if (mainState?.productsGrid?.productsGridFilters?.providers) {
        if (mainState?.productsGrid?.productsGridFilters?.providers?.length > 0) {
          let _filteredProviders = mainState?.productsGrid?.product?.prices[_selectedZone]
              .filter(item => mainState?.productsGrid?.productsGridFilters?.providers?.map((x) => x.id).includes(item.provider_id))
          setFilteredProviders(_filteredProviders);
          filteredProvider = _filteredProviders[0];
          setSelectedProvider(filteredProvider);
        } else {
          setFilteredProviders(filteredProviders);
          setSelectedProvider(filteredProvider);
        }
      }
    }
  }, [mainState?.productsGrid?.product?.prices, selectedZone, sku]);

  useEffect(() => {
    handleChangeDeliveryZone(selectedZone);
  }, [selectedZone]);

  useEffect(() => {

  }, [filteredProviders]);


  const handleChangeDeliveryZone = (code) => {
    if(selectedZone !== code) setSelectedZone(code);

    let filteredProvider = null;
    if(mainState?.productsGrid?.product?.prices && mainState?.productsGrid?.product?.prices[code]) {
      filteredProvider = mainState?.productsGrid?.product?.prices[code][0];

      if (mainState?.productsGrid?.productsGridFilters?.providers?.length > 0) {
        let _filteredProviders = mainState?.productsGrid?.product?.prices[code]
            .filter(item => mainState?.productsGrid?.productsGridFilters?.providers?.map((x) => x.id).includes(item.provider_id))
        setFilteredProviders(_filteredProviders);
        filteredProvider = _filteredProviders[0];
      }
    }
    setSelectedProvider(filteredProvider);
  };

  useEffect(() => {
    mainState?.productsGrid?.product !== null &&
      setProduct(mainState?.productsGrid?.product?.product_detail);
  }, [mainState.productsGrid.product]);

  const skeletonLoading = mainState.productsGrid.loading;

   useEffect(() => {
     const product =
       mainState?.productsGrid?.product?.product_detail?.product_name &&
       history.location.pathname.includes("detail");

   }, [history.location.pathname, mainState.productsGrid]);

   const title = mainState?.productsGrid?.product?.product_detail?.product_name ?? document.title;
  useTitle(title);

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        gap="32px"
        w="75rem"
      >
        <Flex alignItems={"center"}>
          <ProductDetailBreadcrumb
            product={mainState?.productsGrid?.product?.product_detail}
          />
        </Flex>
        <Flex justifyContent={"space-between"} width="100%" gap="32px">
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "32px", md: "32px" }} width={'100%'}>
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: "32px", md: "32px" }}>
                <GridItem colSpan={{ base: 1, md: 1 }}>
                  <ProductDetailMainCard
                      product={mainState?.productsGrid?.product}
                      skeletonLoading={skeletonLoading}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 1 }}>
                  <ProductDetailDescription
                      skeletonLoading={skeletonLoading}
                      product={mainState?.productsGrid?.product?.product_detail}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 1 }}>
                  <ProductDetailSheet
                      product={mainState?.productsGrid?.product}
                      skeletonLoading={skeletonLoading}
                  />
                </GridItem>
              </SimpleGrid>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 1 }}>
              <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: "32px", md: "32px" }} width={'100%'}>
                <GridItem colSpan={{ base: 1, md: 1 }}>
                  <ProductDetailOrderCheckout
                      handleChangeDeliveryZone={handleChangeDeliveryZone}
                      provider={selectedProvider}
                      product={mainState?.productsGrid?.product}
                      selectedZone={selectedZone}
                      skeletonLoading={skeletonLoading}
                  />
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 1 }}>
                  <ProductDetailOptions
                      skeletonLoading={skeletonLoading}
                      providers={filteredProviders}
                      width={'100%'}
                  />
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, productsGrid.actions)(ProductDetailPage)
);
